
var $ = jQuery;

export default function CategoryFilter() {
	new CategoryFilterHandler()
}

class CategoryFilterHandler {
	constructor () {
		// Consts
		this.LOADER = jQuery('#loader-overlay');
		this.PARENT = jQuery('.category-content');
		this.SIDEBAR_LOADER = this.PARENT.find('.sidebar .loader-overlay');
		this.TOPBAR_LOADER = this.PARENT.find('#primary .loader-overlay');
		this.CATEGORY_TOGGLES = this.PARENT.find('.sidebar .category_checkbox');
		this.CATEGORY_GRADES = this.PARENT.find('.sidebar .grade_checkbox');
		this.CATEGORY_SEARCH = this.PARENT.find('#category-search');
		this.CATEGORY_ORDER = this.PARENT.find('#category-order');
		this.CATEGORY_SLIDER = this.PARENT.find('#category-slider-range');
		this.ASSIGNMENTS_DEST = this.PARENT.find('#assignments-destination');
		this.ASSIGNMENTS_PAGINATION = this.PARENT.find('#assignments-pagination');

		// Lets
		this.categories = '';
		this.grades = '';
		this.search_string = '';
		this.order = '';
		this.lengths = {};
		this.search_timer = null;
		this.page = 0;
		this.scrollToTop = false;

		this.init()
	}

	init() {
		this.addEventListeners();
	}

	addEventListeners(){
		jQuery(document).on('click', '.pagination a[data-page]', (e) => {
			e.preventDefault()
			this.page = e.target.getAttribute('data-page')
			this.getDataValues()
			this.scrollToTop = true
		})
		this.CATEGORY_TOGGLES.on('change', () => {
			this.getDataValues()
		});
		this.CATEGORY_GRADES.on('change', () => {
			this.getDataValues()
		});
		this.CATEGORY_ORDER.on('change', () => {
			this.getDataValues()
		});
		this.CATEGORY_SEARCH.on('keyup keypress', function(e) {
			var keyCode = e.keyCode || e.which;
			if (keyCode === 13) {
				e.preventDefault();
				return false;
			}
		});
		this.CATEGORY_SEARCH.on('input', (e) => {
			e.preventDefault();

			if(this.search_timer == null){
				this.search_timer = setTimeout(() => {
					this.getDataValues()
				}, 500);
			}
			else{
				clearTimeout(this.search_timer);
				this.search_timer = setTimeout(() => {
					this.getDataValues()
				}, 500);
			}
		});
		this.activeRangeSlider()
	}

	activeRangeSlider() {
		const MIN = 0
		const MAX = this.CATEGORY_SLIDER.parent().find('[data-slider-max-value]').attr('data-slider-max-value')
		this.CATEGORY_SLIDER.slider({
			range: true,
			min: MIN,
			max: MAX,
			values: [MIN, MAX],
			slide: (event, ui) => {
				let min = ui.values[0]
				let max = ui.values[1]
				this.CATEGORY_SLIDER.parent().find('[data-slider-min-value]').html(min)
				if(ui.values[1] == 80000){
					max = '80000+'
				}
				this.CATEGORY_SLIDER.parent().find('[data-slider-max-value]').html(max)
			},
			stop: (event, ui) => {
				this.debounce(this.getDataValues(), 1000)
			}
		})

		let min = this.CATEGORY_SLIDER.slider('values', 0)
		let max = this.CATEGORY_SLIDER.slider('values', 1)

		if(this.CATEGORY_SLIDER.slider('values', 1) == 5000){
			max = '5000+'
		}

		this.CATEGORY_SLIDER.parent().find('[data-slider-min-value]').html(min)
		this.CATEGORY_SLIDER.parent().find('[data-slider-max-value]').html(max)

	}

	debounce(func, wait, immediate) {
		let timeout

		return function () {
			const context = this
			const args = arguments

			const later = function () {
				timeout = null
				if (!immediate) func.apply(context, args)
			}

			const callNow = immediate && !timeout
			clearTimeout(timeout)
			timeout = setTimeout(later, wait)

			if (callNow) func.apply(context, args)
		}
	}

	getCategoryValues() {
		let categories = '';
		for(var i=0; i<this.CATEGORY_TOGGLES.length; i++) {
			if(this.CATEGORY_TOGGLES[i].checked === true){
				categories += this.CATEGORY_TOGGLES[i].value + ','
			}
		}
		categories = categories.replace(/,\s*$/, "");
		return categories;
	}

	getGradeValues() {
		let grades = '';
		for(var i=0; i<this.CATEGORY_GRADES.length; i++) {
			if(this.CATEGORY_GRADES[i].checked === true){
				grades += this.CATEGORY_GRADES[i].value + ','
			}
		}
		grades = grades.replace(/,\s*$/, "");
		return grades;
	}

	getSearchValue() {
		let search_value = ''
		if(this.CATEGORY_SEARCH)(
			search_value = this.CATEGORY_SEARCH.val()
		)
		return search_value;
	}

	getOrderValue() {
		let order = ''
		if(this.CATEGORY_ORDER)(
			order = this.CATEGORY_ORDER.find(":selected").val()
		)
		return order;
	}

	getLengthValues() {
		let lengths = {}
		if(this.CATEGORY_SLIDER) {
			const MIN = parseInt(this.CATEGORY_SLIDER.parent().find('[data-slider-min-value]').html())
			const MAX = parseInt(this.CATEGORY_SLIDER.parent().find('[data-slider-max-value]').html())
			lengths = {
				min: MIN,
				max: MAX
			}
		}
		return lengths;
	}

	getDataValues(){
		this.categories = this.getCategoryValues()
		this.grades = this.getGradeValues()
		this.search_string = this.getSearchValue()
		this.order = this.getOrderValue()
		this.lengths = this.getLengthValues()

		if(!this.categories){
			if(this.ASSIGNMENTS_DEST.attr('data-term-id')){
				this.categories = this.ASSIGNMENTS_DEST.attr('data-term-id')
			}
		}

		this.getAssignments()
	}



	// Ajax request function
	getAssignments() {

		// Show loader
		this.SIDEBAR_LOADER.addClass('show');
		this.TOPBAR_LOADER.addClass('show');

		var isItGuide =false;
		var documentElement = document.getElementById('is-guide-archive');

		if (documentElement != null)
		{
			isItGuide = (documentElement.value=='1');
		}
		let data = {};
		if(isItGuide)
		{

			data = {
				'action': 'filter_guides',
				'categories': this.categories,
				'grades': this.grades,
				'search_string': this.search_string,
				'order': this.order,
				'lengths': this.lengths,
				'page': this.page,
				'url': window.location.href,
			};
		}
		else
		{

			// Set ajax data
			data = {
				'action': 'filter_assignments',
				'categories': this.categories,
				'grades': this.grades,
				'search_string': this.search_string,
				'order': this.order,
				'lengths': this.lengths,
				'page': this.page,
				'url': window.location.href,
			};
		}

		// Ajax call
		jQuery.post(main_theme_objects.ajax_url, data, (response) => {
			if (response != 0) {

				// Post response
				this.ASSIGNMENTS_DEST.html(response.assignments);
				this.ASSIGNMENTS_PAGINATION.html(response.pagination);

				if(this.scrollToTop){
					// Scroll to top
					const MAIN_HEADER_HEIGHT = document.getElementById('main_header')
					var adminBar = 0;
					if(jQuery('body').hasClass('admin-bar')){
						adminBar = 32;
					}
					var headerOffset = ((MAIN_HEADER_HEIGHT.getBoundingClientRect().height + 32) + adminBar);

					$('html, body').animate({ scrollTop: this.PARENT.offset().top - headerOffset}, 1000);
					this.scrollToTop = false;
				}

				// Hide loader
				this.SIDEBAR_LOADER.removeClass('show');
				this.TOPBAR_LOADER.removeClass('show');
			}
		});
	}
}
/*
function docReady(fn) {
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}


docReady(function() {
	new CategoryFilterHandler();
});*/