import anime from 'animejs'
import { debounce } from '../utils/_debounce'

export default function AssignmentsSlider () {
  const CONTAINERS = document.querySelectorAll('.mtt-assignments')
  if (CONTAINERS.length > 0) {
    for (let i = 0; i < CONTAINERS.length; i++) {
      const slider = new Slider({
        wrapper: CONTAINERS[i],
        innerWrapSelector: '.mtt-assignments__wrap',
        wrapSelector: '.mtt-assignments__items-wrap',
        secondWrapSelector: '.mtt-assignments__items-wrap.second-wrap',
        duration: 50000
      })
      slider.init()
    }
  }
}

class Slider {
  constructor ({ wrapper, innerWrapSelector, wrapSelector, duration, secondWrapSelector }) {
    this.WRAPPER = wrapper
    this.INNER_WRAP = this.WRAPPER.querySelector(innerWrapSelector)
    this.WRAP = this.WRAPPER.querySelector(wrapSelector)
    this.SECOND_WRAP = this.WRAPPER.querySelector(secondWrapSelector)
    this.initialized = false
    this.duration = parseInt(this.WRAPPER.getAttribute('data-duration')) || duration
    this.currentWidth = this.getScreenWidth()
  }

  init () {
    this.initAnimation()
    window.addEventListener('resize', debounce(() => {
      if (this.currentWidth !== this.getScreenWidth()) {
        anime.remove(this.WRAP)
        anime.remove(this.SECOND_WRAP)
        this.initAnimation()
      }
      this.currentWidth = this.getScreenWidth()
    }, 300))
    this.initialized = true
  }

  initAnimation () {
    const wrapperWidths = this.getWrapperWidths()

    const startPoint = wrapperWidths.wrap
    const endPoint = (wrapperWidths.difference + wrapperWidths.innerWrap) * -1

    this.firstAnimation = anime({
      targets: this.WRAP,
      easing: 'linear',
      duration: this.duration,
      translateX:  [0, endPoint],
      translateY: { value: '+=' + 0 },
      translateZ: 0,
      loop: true,
      autoplay: false
    })

    this.SECOND_WRAP.classList.add('absolute')
    this.secondAnimation = anime({
      targets: this.SECOND_WRAP,
      easing: 'linear',
      duration: this.duration,
      translateX: [startPoint, 0],
      translateZ: 0,
      loop: true,
      autoplay: false
    })

    this.firstAnimation.play()
    this.secondAnimation.play()

    !this.initialized && this.WRAPPER.classList.add('show')

    this.animationHandlers()
  }

  animationHandlers () {
    window.addEventListener('blur', () => {
      this.firstAnimation.pause()
      this.secondAnimation.pause()
    })
    window.addEventListener('focus', () => {
      this.firstAnimation.play()
      this.secondAnimation.play()
    })
    const ASSIGNMENTS = this.INNER_WRAP.querySelectorAll('.mtt-assignments__assignment')

    for (let i = 0; i < ASSIGNMENTS.length; i++) {
      const ASSIGNMENT = ASSIGNMENTS[i]
      ASSIGNMENT.addEventListener('mouseenter', () => {
        this.firstAnimation.pause()
        this.secondAnimation.pause()
      })
      ASSIGNMENT.addEventListener('mouseleave', () => {
        this.firstAnimation.play()
        this.secondAnimation.play()
      })
    }
  }

  getWrapperWidths () {
    const innerWrap = this.INNER_WRAP.getBoundingClientRect().width
    const wrap = this.WRAP.getBoundingClientRect().width
    const difference = wrap - innerWrap

    return { innerWrap, wrap, difference }
  }

  getScreenWidth () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
}
