var $ = jQuery;

export default function Search() {
	const SEARCH_CONTAINERS = $('.mtt-search-container')
	if (SEARCH_CONTAINERS.length > 0) {
		for (let i = 0; i < SEARCH_CONTAINERS.length; i++) {
			new SearchHandler(SEARCH_CONTAINERS[i])
		}
	}
}

class SearchHandler {
	constructor (container) {
		// Lets
		this.container = $(container);
		this.search_string = '';
		this.search_timer = null;

		// Consts
		this.SEARCH_INPUT = this.container.find('input[type="search"]');
		this.RESULTS_CONTAINER = this.container.find('.mtt-search-container__results--inner');

		this.init()
	}

	init() {
		this.addEventListeners();
	}

	addEventListeners(){
		this.SEARCH_INPUT.on('focus', (e) => {
			if ($(window).width() > 580) {
				this.container.addClass('focus')
			}
		});
		this.SEARCH_INPUT.on('blur', (e) => {
			if ($(window).width() > 580) {
				this.container.removeClass('focus')
			}
		});
		// this.container.find('form').submit(function (e) {
		// 	if ($(window).width() > 580) {
		// 		e.preventDefault();
		// 	}
		// });
		this.SEARCH_INPUT.on('input', (e) => {
			if ($(window).width() > 580) {
				e.preventDefault();

				if(this.search_timer == null){
					this.search_timer = setTimeout(() => {
						this.getDataValues()
					}, 500);
				}
				else{
					clearTimeout(this.search_timer);
					this.search_timer = setTimeout(() => {
						this.getDataValues()
					}, 500);
				}
			}
		});
	}

	getSearchValue(){
		let search_string = this.SEARCH_INPUT.val()
		return search_string
	}

	getDataValues(){
		this.search_string = this.getSearchValue()

		this.getAssignments()
	}

	// Ajax request function
	getAssignments() {

		// Show loader
		this.container.addClass('searching')

		// Set ajax data
		let data = {
			'action': 'search_assignments',
			'search_string': this.search_string,
			'url': window.location.href,
		};

		// Ajax call
		jQuery.post(main_theme_objects.ajax_url, data, (response) => {
			if (response != 0) {

				// Post response
				this.RESULTS_CONTAINER.html(response.assignments);

				// Hide loader
				this.container.removeClass('searching')
			}
		});
	}
}