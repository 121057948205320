var $ = jQuery;

export default function Single () {
	const ARTICLE = $('.single-post article.post')
	const RELATED_POST = $('.sidebar-related-post')
	const RELATED = $('.yarpp-related')
	if (ARTICLE) {
		const ARTICLE_HEIGHT = ARTICLE.outerHeight();
		if(ARTICLE_HEIGHT < 750){
			// HIDE ALL
			RELATED_POST.hide()
		}else if(ARTICLE_HEIGHT > 750 && ARTICLE_HEIGHT < 850){
			// SHOW FIRST
			RELATED.find('ol').find('a:not(:first-child)').hide()
		}else if(ARTICLE_HEIGHT > 850 && ARTICLE_HEIGHT < 950){
			// SHOW FIRST 2
			RELATED.find('ol').find('a:not(:first-child):not(:nth-child(2)').hide()
		}else if(ARTICLE_HEIGHT > 950 && ARTICLE_HEIGHT < 1050){
			// SHOW FIRST 3
			RELATED.find('ol').find('a:last-child').hide()
		}else {

		}
	}
}