import DOMReady from './utils/_dom-ready'
import Navigation from './modules/_navigation'
import AssignmentsSlider from './modules/_assignments'
import RelatedAssignments from './modules/_related-assignments'
import CategoryFilter from './modules/_category-filter'
import FAQ from './modules/_faq'
import Search from './modules/_search'
import SmoothScroll from './modules/_smooth-scroll'
import Animation from './modules/_animation'
import Single from './modules/_single'
import NumberCounter from './modules/_count-numbers'

DOMReady(() => {
  Navigation()
  AssignmentsSlider()
  RelatedAssignments()
  CategoryFilter()
  FAQ()
  Search()
  SmoothScroll()
  Animation()
  Single()
  NumberCounter()
})
