export default function SmoothScroll() {
	const MAIN_HEADER_HEIGHT = document.getElementById('main_header')
	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();

			var element = document.querySelector(this.getAttribute('href'));
			var adminBar = 0;
			if(jQuery('body').hasClass('admin-bar')){
				adminBar = 32;
			}
			var headerOffset = ((MAIN_HEADER_HEIGHT.getBoundingClientRect().height + 32) + adminBar);
			var elementPosition = element.getBoundingClientRect().top;
			var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			});
		});
	});
}