export default class ScrollHandler {
  constructor ({ el, onEnter = () => {}, delay = 0 }) {
    this.el = el
    this.onEnter = onEnter
    this.delay = delay
    this.entered = false
    this.ticking = false
    this.lastKnownScrollPosition = 0
  }

  init () {
    if (this.el) {
      this.run()
      this.scrollListener()
    }
  }

  /**
   * Scroll event
   */
  scrollListener () {
    document.addEventListener('scroll', (e) => {
      if (!this.entered) {
        this.lastKnownScrollPosition = window.scrollY
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            this.run()
            this.ticking = false
          })
          this.ticking = true
        }
      }
    })
  }

  /**
   * Run event
   */
  run () {
    if (this.elementInView(this.el)) {
      setTimeout(() => {
        this.onEnter()
        this.entered = true
      }, this.delay)
    }
  }

  /**
   * Is element top or bottom in the viewport
   */
  elementInView (el) {
    const BOUNDING = el.getBoundingClientRect()
    if (
      (BOUNDING.top > 0 && BOUNDING.top <= (window.innerHeight || document.documentElement.clientHeight)) || // when top of el is in viewport
      (BOUNDING.bottom > 0 && BOUNDING.bottom <= (window.innerHeight || document.documentElement.clientHeight)) // when bottom of el is in viewport
    ) {
      return true
    }
    return false
  }
}