export default function FAQ () {

  const FAQS = document.querySelectorAll('.mtt-faq')

  if (FAQS.length > 0) {
    for (let i = 0; i < FAQS.length; i++) {
      const FAQ = FAQS[i]
      const CONTAINERS = FAQ.querySelectorAll('.container')

      for (i=0; i<CONTAINERS.length; i++) {
        const HEIGHT = CONTAINERS[i].querySelector('.content-inner').offsetHeight
        const CONTENT = CONTAINERS[i].querySelector('.content')
        CONTENT.setAttribute('data-height', HEIGHT)
        CONTAINERS[i].addEventListener('click', function () {
          this.classList.toggle('active')
          if(this.classList.contains('active')){
            CONTENT.style.height = HEIGHT + 'px'
          }else {
            CONTENT.style.height = '0px'
          }
        })
      }
    }
  }

}