import ScrollHandler from '../utils/_scroll-handler'
import { CountUp } from 'countup.js'

export default function NumberCounter () {

  const NUMBERS = document.querySelectorAll('.count-number')

  if (NUMBERS.length > 0) {
    for (let i = 0; i < NUMBERS.length; i++) {
      const EL = NUMBERS[i]
      const options = {
        separator: '.',
        decimal: ',',
      };
      const scrollHandler = new ScrollHandler({
        el: EL,
        onEnter: () => {
          if(EL.classList.contains('count-number')){
            const NUMBER = EL.querySelector('.number')
            const NUMBER_HIDDEN = EL.querySelector('.hidden')
            if(NUMBER){
              const NUM_WIDTH = NUMBER_HIDDEN.offsetWidth
              const NUM = NUMBER.getAttribute('data-num')
              NUMBER.style.width = (NUM_WIDTH) + 'px'
              const countUp = new CountUp(NUMBER, NUM, options);
              countUp.start();
            }
          }
        },
        delay: 200
      })
      scrollHandler.init()

      window.addEventListener('resize', () => {
        const NUMBERS = document.querySelectorAll('.count-number')
        if(NUMBERS.length > 0){
          for (let i = 0; i < NUMBERS.length; i++) {
            const NUMBER = NUMBERS[i].querySelector('.number')
            const NUMBER_HIDDEN = NUMBERS[i].querySelector('.hidden')
            const NUM_WIDTH = NUMBER_HIDDEN.offsetWidth
            NUMBER.style.width = (NUM_WIDTH + 1) + 'px'
          }
        }
      })
    }
  }

}