// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export default function RelatedAssignments () {

  const ASSIGNMENTS_SLIDERS = document.querySelectorAll('.assignment-slider')

  if (ASSIGNMENTS_SLIDERS.length > 0) {
    for (let i = 0; i < ASSIGNMENTS_SLIDERS.length; i++) {
      const element = ASSIGNMENTS_SLIDERS[i]
      const pagination = element.querySelector(".swiper-pagination")
      const swiperButtonNext = element.parentNode.parentNode.querySelector(".swiper-button-next")
      const swiperButtonPrev = element.parentNode.parentNode.querySelector(".swiper-button-prev")
      var swiper = new Swiper(element, {
        slidesPerView: 4,
        spaceBetween: 16,
        slidesPerGroup: 2,
        loop: true,
        pagination: {
          el: pagination,
          clickable: true,
        },
        navigation: {
          nextEl: swiperButtonNext,
          prevEl: swiperButtonPrev,
        },
        breakpoints: {
          100: { slidesPerView: 1.1 },
          350: { slidesPerView: 1.2 },
          450: { slidesPerView: 1.5 },
          651: { slidesPerView: 2 },
          801: { slidesPerView: 2.5 },
          1001: { slidesPerView: 3 },
          1150: { slidesPerView: 4 }
        },
        on: {
          init: function () {
            element.classList.remove("hidden")
          },
        },
      });
    }
  }

}
