import ScrollHandler from '../utils/_scroll-handler'
import countUp from 'countup.js'

export default function Animation () {
	const ELEMENTS = document.querySelectorAll('section')
	if (ELEMENTS.length > 0) {
		for (let i = 0; i < ELEMENTS.length; i++) {
			const EL = ELEMENTS[i]
			const scrollHandler = new ScrollHandler({
				el: EL,
				onEnter: () => EL.classList.add('show'),
				delay: 200
			})
			scrollHandler.init()
		}
	}
}
